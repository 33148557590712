.header {
  overflow: hidden;
  background-color: #f1f1f1;
  transition: margin 0.2s ease-in-out;
  text-align: center;
  padding: 50px 10px;
  margin-bottom: 30px;
}

.header a {
  color: blue;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

.header a.logo {
  font-size: 45px;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}

.float-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.float-on-hover:hover, .float-on-hover:focus, .float-on-hover:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
}