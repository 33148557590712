.map-container {
    width: 100%;
    height: 50vh;
  }

.middle {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-size: 20px;
}

p {
  text-align: center;
}

.title {
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  font-size: 35px;
}

  .leftside {
    padding: 0px 20px;
  }